@font-face {
  font-family: 'Messina';
  font-display: swap;
  src: url('../../public/fonts/messina/MessinaSansWeb-Light.eot');
  src: url('../../public/fonts/messina/MessinaSansWeb-Light.eot?#iefix') format('embedded-opentype'),
    url('../../public/fonts/messina/MessinaSansWeb-Light.woff2') format('woff2'),
    url('../../public/fonts/messina/MessinaSansWeb-Light.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Messina';
  font-display: swap;
  src: url('../../public/fonts/messina/MessinaSansWeb-LightItalic.eot');
  src: url('../../public/fonts/messina/MessinaSansWeb-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('../../public/fonts/messina/MessinaSansWeb-LightItalic.woff2') format('woff2'),
    url('../../public/fonts/messina/MessinaSansWeb-LightItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Messina';
  font-display: swap;
  src: url('../../public/fonts/messina/MessinaSansWeb-Book.eot');
  src: url('../../public/fonts/messina/MessinaSansWeb-Book.eot?#iefix') format('embedded-opentype'),
    url('../../public/fonts/messina/MessinaSansWeb-Book.woff2') format('woff2'),
    url('../../public/fonts/messina/MessinaSansWeb-Book.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Messina';
  font-display: swap;
  src: url('../../public/fonts/messina/MessinaSansWeb-BookItalic.eot');
  src: url('../../public/fonts/messina/MessinaSansWeb-BookItalic.eot?#iefix') format('embedded-opentype'),
    url('../../public/fonts/messina/MessinaSansWeb-BookItalic.woff2') format('woff2'),
    url('../../public/fonts/messina/MessinaSansWeb-BookItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Messina';
  font-display: swap;
  src: url('../../public/fonts/messina/MessinaSansWeb-Regular.eot');
  src: url('../../public/fonts/messina/MessinaSansWeb-Regular.eot?#iefix') format('embedded-opentype'),
    url('../../public/fonts/messina/MessinaSansWeb-Regular.woff2') format('woff2'),
    url('../../public/fonts/messina/MessinaSansWeb-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Messina';
  font-display: swap;
  src: url('../../public/fonts/messina/MessinaSansWeb-RegularItalic.eot');
  src: url('../../public/fonts/messina/MessinaSansWeb-RegularItalic.eot?#iefix') format('embedded-opentype'),
    url('../../public/fonts/messina/MessinaSansWeb-RegularItalic.woff2') format('woff2'),
    url('../../public/fonts/messina/MessinaSansWeb-RegularItalic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Messina';
  font-display: swap;
  src: url('../../public/fonts/messina/MessinaSansWeb-SemiBold.eot');
  src: url('../../public/fonts/messina/MessinaSansWeb-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('../../public/fonts/messina/MessinaSansWeb-SemiBold.woff2') format('woff2'),
    url('../../public/fonts/messina/MessinaSansWeb-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Messina';
  font-display: swap;
  src: url('../../public/fonts/messina/MessinaSansWeb-SemiBoldItalic.eot');
  src: url('../../public/fonts/messina/MessinaSansWeb-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../../public/fonts/messina/MessinaSansWeb-SemiBoldItalic.woff2') format('woff2'),
    url('../../public/fonts/messina/MessinaSansWeb-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Messina';
  font-display: swap;
  src: url('../../public/fonts/messina/MessinaSansWeb-Bold.eot');
  src: url('../../public/fonts/messina/MessinaSansWeb-Bold.eot?#iefix') format('embedded-opentype'),
    url('../../public/fonts/messina/MessinaSansWeb-Bold.woff2') format('woff2'),
    url('../../public/fonts/messina/MessinaSansWeb-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Messina';
  font-display: swap;
  src: url('../../public/fonts/messina/MessinaSansWeb-BoldItalic.eot');
  src: url('../../public/fonts/messina/MessinaSansWeb-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../../public/fonts/messina/MessinaSansWeb-BoldItalic.woff2') format('woff2'),
    url('../../public/fonts/messina/MessinaSansWeb-BoldItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Messina';
  font-display: swap;
  src: url('../../public/fonts/messina/MessinaSansWeb-Black.eot');
  src: url('../../public/fonts/messina/MessinaSansWeb-Black.eot?#iefix') format('embedded-opentype'),
    url('../../public/fonts/messina/MessinaSansWeb-Black.woff2') format('woff2'),
    url('../../public/fonts/messina/MessinaSansWeb-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Messina';
  font-display: swap;
  src: url('../../public/fonts/messina/MessinaSansWeb-BlackItalic.eot');
  src: url('../../public/fonts/messina/MessinaSansWeb-BlackItalic.eot?#iefix') format('embedded-opentype'),
    url('../../public/fonts/messina/MessinaSansWeb-BlackItalic.woff2') format('woff2'),
    url('../../public/fonts/messina/MessinaSansWeb-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

/* purgecss start ignore */
@import './fonts.pcss';

@tailwind base;
@tailwind components;
/* purgecss end ignore */

@tailwind utilities;

html {
  font-size: 14px;
  font-family: Messina, sans-serif;
  line-height: 1.5;
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

b {
  font-weight: 600;
}

a,
a:active,
a:focus {
  @apply outline-none border-none;
}

button:focus {
  @apply outline-none;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@layer base {
  *,
  ::before,
  ::after {
    @apply border-light-base dark:border-dark-base;
  }
}

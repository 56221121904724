.text-blur-black {
  color: transparent;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.text-blur-black:hover {
  color: initial;
  text-shadow: initial;
}

.text-blur-white {
  color: transparent;
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
  overflow: visible !important;
}

.text-blur-white:hover {
  color: white;
  text-shadow: initial;
}

.tt_table [role='row'] {
  --bg-opacity: 0;
}

.tt_table [role='row'] .row_visible_if_hovered {
  visibility: hidden;
}

.tt_table [role='row']:hover,
.tt_table [role='row'].tt_table-force_hover {
  --bg-opacity: 1;
}

.tt_table [role='row']:hover .row_visible_if_hovered,
.tt_table [role='row'].tt_table-force_hover {
  visibility: visible;
}

.tt_table [role='row'] .row_hidden_if_hovered {
  display: initial;
}

.tt_table [role='row']:hover .row_hidden_if_hovered {
  display: none;
}

.ReactModal__Overlay {
  overflow: auto;
}

.ReactModal__Overlay :not(.custom-select__menu-list)::-webkit-scrollbar {
  display: none;
}

.ReactModal__Content {
  max-height: none;
  margin-top: 0;
}

.tt_table [role="row"] {
  --bg-opacity: 0;
}

.tt_table [role="row"] .row_visible_if_hovered {
  visibility: hidden;
}

.tt_table [role="row"]:hover, .tt_table [role="row"].tt_table-force_hover {
  --bg-opacity: 1;
}

.tt_table [role="row"]:hover .row_visible_if_hovered, .tt_table [role="row"].tt_table-force_hover {
  visibility: visible;
}

.tt_table [role="row"] .row_hidden_if_hovered {
  display: initial;
}

.tt_table [role="row"]:hover .row_hidden_if_hovered {
  display: none;
}

.rdt {
  position: relative;
}

.rdtPicker {
  min-width: 250px;
  background: #fff;
  border: 1px solid #f9f9f9;
  margin-top: 1px;
  padding: 4px;
  display: none;
  position: absolute;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .1);
  z-index: 99999 !important;
}

.rdtOpen .rdtPicker {
  display: block;
}

.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}

.rdtPicker .rdtTimeToggle {
  text-align: center;
}

.rdtPicker table {
  width: 100%;
  margin: 0;
}

.rdtPicker td, .rdtPicker th {
  text-align: center;
  height: 28px;
}

.rdtPicker td {
  cursor: pointer;
}

.rdtPicker td.rdtDay:hover, .rdtPicker td.rdtHour:hover, .rdtPicker td.rdtMinute:hover, .rdtPicker td.rdtSecond:hover, .rdtPicker .rdtTimeToggle:hover {
  cursor: pointer;
  background: #eee;
}

.rdtPicker td.rdtOld, .rdtPicker td.rdtNew {
  color: #999;
}

.rdtPicker td.rdtToday {
  position: relative;
}

.rdtPicker td.rdtToday:before {
  content: "";
  border-top-color: rgba(0, 0, 0, .2);
  border-bottom: 7px solid #428bca;
  border-left: 7px solid rgba(0, 0, 0, 0);
  display: inline-block;
  position: absolute;
  bottom: 4px;
  right: 4px;
}

.rdtPicker td.rdtActive, .rdtPicker td.rdtActive:hover {
  color: #fff;
  text-shadow: 0 -1px rgba(0, 0, 0, .25);
  background-color: #428bca;
}

.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff;
}

.rdtPicker td.rdtDisabled, .rdtPicker td.rdtDisabled:hover {
  color: #999;
  cursor: not-allowed;
  background: none;
}

.rdtPicker td span.rdtOld {
  color: #999;
}

.rdtPicker td span.rdtDisabled, .rdtPicker td span.rdtDisabled:hover {
  color: #999;
  cursor: not-allowed;
  background: none;
}

.rdtPicker th {
  border-bottom: 1px solid #f9f9f9;
}

.rdtPicker .dow {
  width: 14.2857%;
  cursor: default;
  border-bottom: none;
}

.rdtPicker th.rdtSwitch {
  width: 100px;
}

.rdtPicker th.rdtNext, .rdtPicker th.rdtPrev {
  vertical-align: top;
  font-size: 21px;
}

.rdtPrev span, .rdtNext span {
  -webkit-touch-callout: none;
  -ms-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  display: block;
}

.rdtPicker th.rdtDisabled, .rdtPicker th.rdtDisabled:hover {
  color: #999;
  cursor: not-allowed;
  background: none;
}

.rdtPicker thead tr:first-of-type th {
  cursor: pointer;
}

.rdtPicker thead tr:first-of-type th:hover {
  background: #eee;
}

.rdtPicker tfoot {
  border-top: 1px solid #f9f9f9;
}

.rdtPicker button {
  cursor: pointer;
  background: none;
  border: none;
}

.rdtPicker button:hover {
  background-color: #eee;
}

.rdtPicker thead button {
  width: 100%;
  height: 100%;
}

td.rdtMonth, td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
}

td.rdtMonth:hover, td.rdtYear:hover {
  background: #eee;
}

.rdtCounters {
  display: inline-block;
}

.rdtCounters > div {
  float: left;
}

.rdtCounter {
  height: 100px;
  width: 40px;
}

.rdtCounterSeparator {
  line-height: 100px;
}

.rdtCounter .rdtBtn {
  height: 40%;
  cursor: pointer;
  -webkit-touch-callout: none;
  -ms-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  line-height: 40px;
  display: block;
}

.rdtCounter .rdtBtn:hover {
  background: #eee;
}

.rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em;
}

.rdtMilli {
  vertical-align: middle;
  width: 48px;
  padding-left: 8px;
}

.rdtMilli input {
  width: 100%;
  margin-top: 37px;
  font-size: 1.2em;
}

.rdtTime td {
  cursor: default;
}

.rdtPicker {
  --tw-border-opacity: 1;
  border-color: rgba(226, 226, 226, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(252, 252, 252, var(--tw-bg-opacity));
  border-radius: .25rem;
}

:is(.dark .rdtPicker) {
  --tw-border-opacity: 1;
  border-color: rgba(52, 52, 52, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(28, 28, 28, var(--tw-bg-opacity));
}

.rdtPicker th {
  --tw-border-opacity: 1;
  border-color: rgba(226, 226, 226, var(--tw-border-opacity));
}

:is(.dark .rdtPicker th) {
  --tw-border-opacity: 1;
  border-color: rgba(52, 52, 52, var(--tw-border-opacity));
}

.rdtPicker thead tr:first-of-type th {
  --tw-text-opacity: 1;
  color: rgba(23, 23, 23, var(--tw-text-opacity));
  font-weight: 700;
}

:is(.dark .rdtPicker thead tr:first-of-type th) {
  --tw-text-opacity: 1;
  color: rgba(237, 237, 237, var(--tw-text-opacity));
}

.rdtPicker thead tr:first-of-type th:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 252, 252, var(--tw-bg-opacity));
}

:is(.dark .rdtPicker thead tr:first-of-type th:hover) {
  --tw-bg-opacity: 1;
  background-color: rgba(28, 28, 28, var(--tw-bg-opacity));
}

.rdtPicker td.rdtDay:hover, .rdtPicker td.rdtHour:hover, .rdtPicker td.rdtMinute:hover, .rdtPicker td.rdtSecond:hover, .rdtPicker .rdtTimeToggle:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 243, 243, var(--tw-bg-opacity));
  border-radius: .25rem;
}

:is(.dark .rdtPicker td.rdtDay:hover), :is(.dark .rdtPicker td.rdtHour:hover), :is(.dark .rdtPicker td.rdtMinute:hover), :is(.dark .rdtPicker td.rdtSecond:hover), :is(.dark .rdtPicker .rdtTimeToggle:hover) {
  --tw-bg-opacity: 1;
  background-color: rgba(40, 40, 40, var(--tw-bg-opacity));
}

td.rdtMonth:hover, td.rdtYear:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 243, 243, var(--tw-bg-opacity));
  border-radius: .25rem;
}

:is(.dark td.rdtMonth:hover), :is(.dark td.rdtYear:hover) {
  --tw-bg-opacity: 1;
  background-color: rgba(40, 40, 40, var(--tw-bg-opacity));
}

.rdtPicker td.rdtActive.rdtToday:before, .rdtPicker td.rdtToday:before {
  --tw-border-opacity: 1;
  border-bottom-color: rgba(73, 72, 238, var(--tw-border-opacity));
}

:is(.dark .rdtPicker td.rdtActive.rdtToday):before, :is(.dark .rdtPicker td.rdtToday):before {
  --tw-border-opacity: 1;
  border-bottom-color: rgba(132, 157, 255, var(--tw-border-opacity));
}

.rdtPicker td.rdtActive, .rdtPicker td.rdtActive:hover {
  text-shadow: none;
  --tw-bg-opacity: 1;
  background-color: rgba(234, 234, 254, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(23, 23, 23, var(--tw-text-opacity));
  border-radius: .25rem;
}

:is(.dark .rdtPicker td.rdtActive), :is(.dark .rdtPicker td.rdtActive:hover) {
  --tw-bg-opacity: 1;
  background-color: rgba(34, 52, 110, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(237, 237, 237, var(--tw-text-opacity));
}

.rdtPicker td.rdtOld, .rdtPicker td.rdtNew {
  --tw-text-opacity: 1;
  color: rgba(199, 199, 199, var(--tw-text-opacity));
}

:is(.dark .rdtPicker td.rdtOld), :is(.dark .rdtPicker td.rdtNew) {
  --tw-text-opacity: 1;
  color: rgba(80, 80, 80, var(--tw-text-opacity));
}

.rdtPicker tfoot {
  --tw-border-opacity: 1;
  border-top-width: 1px;
  border-color: rgba(226, 226, 226, var(--tw-border-opacity));
}

:is(.dark .rdtPicker tfoot) {
  --tw-border-opacity: 1;
  border-color: rgba(52, 52, 52, var(--tw-border-opacity));
}

.rdtCounter .rdtBtn:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 243, 243, var(--tw-bg-opacity));
}

:is(.dark .rdtCounter .rdtBtn:hover) {
  --tw-bg-opacity: 1;
  background-color: rgba(40, 40, 40, var(--tw-bg-opacity));
}

.tippy-box[data-animation="fade"][data-state="hidden"] {
  opacity: 0;
}

[data-tippy-root] {
  max-width: calc(100vw - 10px);
}

.tippy-box {
  color: #fff;
  --tw-bg-opacity: 1;
  background-color: rgba(23, 23, 23, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  border-radius: 4px;
  outline: 0;
  font-size: 13px;
  line-height: 1.5;
  transition-property: transform, visibility, opacity;
  position: relative;
}

.tippy-box[data-placement^="top"] > .tippy-arrow {
  bottom: 0;
}

.tippy-box[data-placement^="top"] > .tippy-arrow:before {
  transform-origin: top;
  border-width: 8px 8px 0;
  border-color: rgba(0, 0, 0, 0);
  bottom: -7px;
  left: 0;
  border-top-color: initial !important;
}

.tippy-box[data-placement^="bottom"] > .tippy-arrow {
  top: 0;
}

.tippy-box[data-placement^="bottom"] > .tippy-arrow:before {
  border-width: 0 8px 8px;
  border-bottom-color: initial;
  transform-origin: bottom;
  top: -7px;
  left: 0;
}

.tippy-box[data-placement^="left"] > .tippy-arrow {
  right: 0;
}

.tippy-box[data-placement^="left"] > .tippy-arrow:before {
  border-width: 8px 0 8px 8px;
  border-left-color: initial;
  transform-origin: 0;
  right: -7px;
}

.tippy-box[data-placement^="right"] > .tippy-arrow {
  left: 0;
}

.tippy-box[data-placement^="right"] > .tippy-arrow:before {
  border-width: 8px 8px 8px 0;
  border-right-color: initial;
  transform-origin: 100%;
  left: -7px;
}

.tippy-box[data-inertia][data-state="visible"] {
  transition-timing-function: cubic-bezier(.54, 1.5, .38, 1.11);
}

.tippy-arrow {
  width: 16px;
  height: 16px;
  --tw-text-opacity: 1;
  color: rgba(23, 23, 23, var(--tw-text-opacity));
}

.tippy-arrow:before {
  content: "";
  border-style: solid;
  border-color: rgba(0, 0, 0, 0);
  position: absolute;
}

.tippy-content {
  z-index: 1;
  padding: 1em;
  position: relative;
}

.filterable_cell_wrapper .filterable_cell_wrapper--icon {
  visibility: hidden;
  background-color: inherit;
}

.filterable_cell_wrapper:hover .filterable_cell_wrapper--icon {
  visibility: visible;
}

.text-blur-black {
  color: rgba(0, 0, 0, 0);
  text-shadow: 0 0 10px rgba(0, 0, 0, .5);
}

.text-blur-black:hover {
  color: initial;
  text-shadow: initial;
}

.text-blur-white {
  color: rgba(0, 0, 0, 0);
  text-shadow: 0 0 5px rgba(255, 255, 255, .5);
  overflow: visible !important;
}

.text-blur-white:hover {
  color: #fff;
  text-shadow: initial;
}

@font-face {
  font-family: Messina;
  font-display: swap;
  src: url("MessinaSansWeb-Light.d2a969ff.eot");
  src: url("MessinaSansWeb-Light.d2a969ff.eot#iefix") format("embedded-opentype"), url("MessinaSansWeb-Light.712cc0ea.woff2") format("woff2"), url("MessinaSansWeb-Light.7a82417c.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: Messina;
  font-display: swap;
  src: url("MessinaSansWeb-LightItalic.75c0b870.eot");
  src: url("MessinaSansWeb-LightItalic.75c0b870.eot#iefix") format("embedded-opentype"), url("MessinaSansWeb-LightItalic.fc89359c.woff2") format("woff2"), url("MessinaSansWeb-LightItalic.4b60f7d2.woff") format("woff");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: Messina;
  font-display: swap;
  src: url("MessinaSansWeb-Book.8790b40f.eot");
  src: url("MessinaSansWeb-Book.8790b40f.eot#iefix") format("embedded-opentype"), url("MessinaSansWeb-Book.5046e1b0.woff2") format("woff2"), url("MessinaSansWeb-Book.55b3f6de.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Messina;
  font-display: swap;
  src: url("MessinaSansWeb-BookItalic.4a724814.eot");
  src: url("MessinaSansWeb-BookItalic.4a724814.eot#iefix") format("embedded-opentype"), url("MessinaSansWeb-BookItalic.b7abb883.woff2") format("woff2"), url("MessinaSansWeb-BookItalic.a79cb72c.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: Messina;
  font-display: swap;
  src: url("MessinaSansWeb-Regular.fdaba220.eot");
  src: url("MessinaSansWeb-Regular.fdaba220.eot#iefix") format("embedded-opentype"), url("MessinaSansWeb-Regular.db593232.woff2") format("woff2"), url("MessinaSansWeb-Regular.90f2e0f6.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Messina;
  font-display: swap;
  src: url("MessinaSansWeb-RegularItalic.a57f55f3.eot");
  src: url("MessinaSansWeb-RegularItalic.a57f55f3.eot#iefix") format("embedded-opentype"), url("MessinaSansWeb-RegularItalic.749fb90f.woff2") format("woff2"), url("MessinaSansWeb-RegularItalic.f57c4247.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: Messina;
  font-display: swap;
  src: url("MessinaSansWeb-SemiBold.6770ac56.eot");
  src: url("MessinaSansWeb-SemiBold.6770ac56.eot#iefix") format("embedded-opentype"), url("MessinaSansWeb-SemiBold.5abb3af3.woff2") format("woff2"), url("MessinaSansWeb-SemiBold.c451fbe9.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Messina;
  font-display: swap;
  src: url("MessinaSansWeb-SemiBoldItalic.ff8d452e.eot");
  src: url("MessinaSansWeb-SemiBoldItalic.ff8d452e.eot#iefix") format("embedded-opentype"), url("MessinaSansWeb-SemiBoldItalic.fc77cd2b.woff2") format("woff2"), url("MessinaSansWeb-SemiBoldItalic.c4ef5c2e.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: Messina;
  font-display: swap;
  src: url("MessinaSansWeb-Bold.b99ff474.eot");
  src: url("MessinaSansWeb-Bold.b99ff474.eot#iefix") format("embedded-opentype"), url("MessinaSansWeb-Bold.04a60352.woff2") format("woff2"), url("MessinaSansWeb-Bold.ec17f23d.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Messina;
  font-display: swap;
  src: url("MessinaSansWeb-BoldItalic.b40999c6.eot");
  src: url("MessinaSansWeb-BoldItalic.b40999c6.eot#iefix") format("embedded-opentype"), url("MessinaSansWeb-BoldItalic.6eb84961.woff2") format("woff2"), url("MessinaSansWeb-BoldItalic.f47c57f1.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: Messina;
  font-display: swap;
  src: url("MessinaSansWeb-Black.aab934ae.eot");
  src: url("MessinaSansWeb-Black.aab934ae.eot#iefix") format("embedded-opentype"), url("MessinaSansWeb-Black.843f32fb.woff2") format("woff2"), url("MessinaSansWeb-Black.1bd758e0.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: Messina;
  font-display: swap;
  src: url("MessinaSansWeb-BlackItalic.82cffede.eot");
  src: url("MessinaSansWeb-BlackItalic.82cffede.eot#iefix") format("embedded-opentype"), url("MessinaSansWeb-BlackItalic.20d0525a.woff2") format("woff2"), url("MessinaSansWeb-BlackItalic.98d3738a.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::-ms-input-placeholder, textarea::-ms-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after {
  --tw-border-opacity: 1;
  border-color: rgba(226, 226, 226, var(--tw-border-opacity));
}

:is(.dark *), :is(.dark):before, :is(.dark):after {
  --tw-border-opacity: 1;
  border-color: rgba(52, 52, 52, var(--tw-border-opacity));
}

*, :before, :after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, .5);
  --tw-ring-offset-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-ring-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, .5);
  --tw-ring-offset-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-ring-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.\!container {
  width: 100% !important;
}

.container {
  width: 100%;
}

@media (min-width: 480px) {
  .\!container {
    max-width: 480px !important;
  }

  .container {
    max-width: 480px;
  }
}

@media (min-width: 640px) {
  .\!container {
    max-width: 640px !important;
  }

  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .\!container {
    max-width: 768px !important;
  }

  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .\!container {
    max-width: 1024px !important;
  }

  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .\!container {
    max-width: 1280px !important;
  }

  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .\!container {
    max-width: 1536px !important;
  }

  .container {
    max-width: 1536px;
  }
}

.pointer-events-none {
  pointer-events: none;
}

.\!visible {
  visibility: visible !important;
}

.visible {
  visibility: visible;
}

.\!invisible {
  visibility: hidden !important;
}

.invisible {
  visibility: hidden;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.\!absolute {
  position: absolute !important;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.\!sticky {
  position: sticky !important;
}

.sticky {
  position: sticky;
}

.inset-0 {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.inset-auto {
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
}

.bottom-0 {
  bottom: 0;
}

.bottom-\[-1px\] {
  bottom: -1px;
}

.bottom-\[16px\] {
  bottom: 16px;
}

.bottom-\[24px\] {
  bottom: 24px;
}

.left-0 {
  left: 0;
}

.left-1\/2 {
  left: 50%;
}

.left-3 {
  left: .75rem;
}

.left-\[-26px\] {
  left: -26px;
}

.left-\[50\%\] {
  left: 50%;
}

.right-0 {
  right: 0;
}

.right-12 {
  right: 3rem;
}

.right-3 {
  right: .75rem;
}

.right-4 {
  right: 1rem;
}

.right-\[-12px\] {
  right: -12px;
}

.right-\[10px\] {
  right: 10px;
}

.right-\[8px\] {
  right: 8px;
}

.top-0 {
  top: 0;
}

.top-1\/2 {
  top: 50%;
}

.top-10 {
  top: 2.5rem;
}

.top-4 {
  top: 1rem;
}

.top-\[-36px\] {
  top: -36px;
}

.top-\[1px\] {
  top: 1px;
}

.top-\[2px\] {
  top: 2px;
}

.top-\[3\.5px\] {
  top: 3.5px;
}

.top-\[3px\] {
  top: 3px;
}

.top-\[50\%\] {
  top: 50%;
}

.top-\[62px\] {
  top: 62px;
}

.top-\[8px\] {
  top: 8px;
}

.-z-1 {
  z-index: -1;
}

.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-50 {
  z-index: 50;
}

.z-9999 {
  z-index: 9999;
}

.z-\[100000\] {
  z-index: 100000;
}

.z-\[1000\] {
  z-index: 1000;
}

.z-\[200\] {
  z-index: 200;
}

.z-\[999\] {
  z-index: 999;
}

.z-auto {
  z-index: auto;
}

.z-max {
  z-index: 2147483647;
}

.order-1 {
  order: 1;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-12 {
  grid-column: span 12 / span 12;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.col-span-4 {
  grid-column: span 4 / span 4;
}

.col-span-5 {
  grid-column: span 5 / span 5;
}

.col-span-6 {
  grid-column: span 6 / span 6;
}

.col-span-7 {
  grid-column: span 7 / span 7;
}

.float-right {
  float: right;
}

.m-0 {
  margin: 0;
}

.m-16 {
  margin: 4rem;
}

.m-6 {
  margin: 1.5rem;
}

.m-8 {
  margin: 2rem;
}

.m-auto {
  margin: auto;
}

.-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.-mx-6 {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.-my-4 {
  margin-top: -1rem;
  margin-bottom: -1rem;
}

.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.my-7 {
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.-mb-16 {
  margin-bottom: -4rem;
}

.-mb-2 {
  margin-bottom: -.5rem;
}

.-mb-\[2px\] {
  margin-bottom: -2px;
}

.-mb-px {
  margin-bottom: -1px;
}

.-ml-1 {
  margin-left: -.25rem;
}

.-ml-1\.5 {
  margin-left: -.375rem;
}

.-ml-2 {
  margin-left: -.5rem;
}

.-ml-4 {
  margin-left: -1rem;
}

.-ml-6 {
  margin-left: -1.5rem;
}

.-ml-\[1px\] {
  margin-left: -1px;
}

.-ml-\[2px\] {
  margin-left: -2px;
}

.-mr-\[1px\], .-mr-px {
  margin-right: -1px;
}

.-mt-0 {
  margin-top: 0;
}

.-mt-0\.5 {
  margin-top: -.125rem;
}

.-mt-14 {
  margin-top: -3.5rem;
}

.-mt-2 {
  margin-top: -.5rem;
}

.-mt-6 {
  margin-top: -1.5rem;
}

.-mt-\[1px\] {
  margin-top: -1px;
}

.-mt-\[2px\] {
  margin-top: -2px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-7 {
  margin-bottom: 1.75rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-9 {
  margin-bottom: 2.25rem;
}

.ml-0 {
  margin-left: 0;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-1\.5 {
  margin-left: .375rem;
}

.ml-10 {
  margin-left: 2.5rem;
}

.ml-12 {
  margin-left: 3rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.ml-8 {
  margin-left: 2rem;
}

.ml-\[1rem\] {
  margin-left: 1rem;
}

.ml-auto {
  margin-left: auto;
}

.mr-0 {
  margin-right: 0;
}

.mr-0\.5 {
  margin-right: .125rem;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-10 {
  margin-right: 2.5rem;
}

.mr-12 {
  margin-right: 3rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-5 {
  margin-right: 1.25rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.mr-8 {
  margin-right: 2rem;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-14 {
  margin-top: 3.5rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-\[-2px\] {
  margin-top: -2px;
}

.mt-px {
  margin-top: 1px;
}

.box-border {
  box-sizing: border-box;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.\!hidden {
  display: none !important;
}

.hidden {
  display: none;
}

.h-0 {
  height: 0;
}

.h-1 {
  height: .25rem;
}

.h-1\.5 {
  height: .375rem;
}

.h-10 {
  height: 2.5rem;
}

.h-12 {
  height: 3rem;
}

.h-2 {
  height: .5rem;
}

.h-3 {
  height: .75rem;
}

.h-32 {
  height: 8rem;
}

.h-4 {
  height: 1rem;
}

.h-40 {
  height: 10rem;
}

.h-48 {
  height: 12rem;
}

.h-5 {
  height: 1.25rem;
}

.h-64 {
  height: 16rem;
}

.h-7 {
  height: 1.75rem;
}

.h-8 {
  height: 2rem;
}

.h-96 {
  height: 24rem;
}

.h-\[16px\] {
  height: 16px;
}

.h-\[18px\] {
  height: 18px;
}

.h-\[200px\] {
  height: 200px;
}

.h-\[24px\] {
  height: 24px;
}

.h-\[24rem\] {
  height: 24rem;
}

.h-\[25px\] {
  height: 25px;
}

.h-\[300px\] {
  height: 300px;
}

.h-\[325px\] {
  height: 325px;
}

.h-\[3px\] {
  height: 3px;
}

.h-\[400px\] {
  height: 400px;
}

.h-\[52px\] {
  height: 52px;
}

.h-\[calc\(100vh-380px\)\] {
  height: calc(100vh - 380px);
}

.h-\[calc\(100vh-52px\)\] {
  height: calc(100vh - 52px);
}

.h-auto {
  height: auto;
}

.h-fit {
  height: fit-content;
}

.h-full {
  height: 100%;
}

.h-px {
  height: 1px;
}

.h-screen {
  height: 100vh;
}

.max-h-\[240px\] {
  max-height: 240px;
}

.max-h-\[28px\] {
  max-height: 28px;
}

.max-h-\[50vh\] {
  max-height: 50vh;
}

.max-h-full {
  max-height: 100%;
}

.min-h-96 {
  min-height: 24rem;
}

.min-h-\[30px\] {
  min-height: 30px;
}

.min-h-\[32px\] {
  min-height: 32px;
}

.min-h-\[400px\] {
  min-height: 400px;
}

.min-h-\[52px\] {
  min-height: 52px;
}

.min-h-\[56px\] {
  min-height: 56px;
}

.min-h-\[calc\(100vh-52px\)\] {
  min-height: calc(100vh - 52px);
}

.min-h-full {
  min-height: 100%;
}

.min-h-screen {
  min-height: 100vh;
}

.w-0 {
  width: 0;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.3333%;
}

.w-1\/4 {
  width: 25%;
}

.w-112 {
  width: 28rem;
}

.w-12 {
  width: 3rem;
}

.w-152 {
  width: 38rem;
}

.w-2 {
  width: .5rem;
}

.w-2\/3 {
  width: 66.6667%;
}

.w-2\/4 {
  width: 50%;
}

.w-2\/5 {
  width: 40%;
}

.w-20 {
  width: 5rem;
}

.w-28 {
  width: 7rem;
}

.w-3 {
  width: .75rem;
}

.w-3\/4 {
  width: 75%;
}

.w-32 {
  width: 8rem;
}

.w-4 {
  width: 1rem;
}

.w-40 {
  width: 10rem;
}

.w-44 {
  width: 11rem;
}

.w-48 {
  width: 12rem;
}

.w-7 {
  width: 1.75rem;
}

.w-72 {
  width: 18rem;
}

.w-8 {
  width: 2rem;
}

.w-96 {
  width: 24rem;
}

.w-\[120px\] {
  width: 120px;
}

.w-\[168px\] {
  width: 168px;
}

.w-\[16px\] {
  width: 16px;
}

.w-\[180px\] {
  width: 180px;
}

.w-\[240px\] {
  width: 240px;
}

.w-\[25px\] {
  width: 25px;
}

.w-\[270px\] {
  width: 270px;
}

.w-\[340px\] {
  width: 340px;
}

.w-\[3px\] {
  width: 3px;
}

.w-\[52px\] {
  width: 52px;
}

.w-auto {
  width: auto;
}

.w-fit {
  width: fit-content;
}

.w-full {
  width: 100%;
}

.w-px {
  width: 1px;
}

.w-screen {
  width: 100vw;
}

.min-w-20 {
  min-width: 5rem;
}

.min-w-\[160px\] {
  min-width: 160px;
}

.min-w-\[50rem\] {
  min-width: 50rem;
}

.min-w-\[60px\] {
  min-width: 60px;
}

.min-w-\[800px\] {
  min-width: 800px;
}

.min-w-full {
  min-width: 100%;
}

.min-w-max {
  min-width: max-content;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-4xl {
  max-width: 56rem;
}

.max-w-5xl {
  max-width: 64rem;
}

.max-w-6xl {
  max-width: 72rem;
}

.max-w-7xl {
  max-width: 80rem;
}

.max-w-8xl {
  max-width: 88rem;
}

.max-w-9xl {
  max-width: 96rem;
}

.max-w-\[100px\] {
  max-width: 100px;
}

.max-w-\[1360px\] {
  max-width: 1360px;
}

.max-w-\[180px\] {
  max-width: 180px;
}

.max-w-\[240px\] {
  max-width: 240px;
}

.max-w-\[280px\] {
  max-width: 280px;
}

.max-w-\[32rem\] {
  max-width: 32rem;
}

.max-w-\[4rem\] {
  max-width: 4rem;
}

.max-w-full {
  max-width: 100%;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-xl {
  max-width: 36rem;
}

.flex-1 {
  flex: 1;
}

.flex-auto {
  flex: auto;
}

.flex-initial {
  flex: 0 auto;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.shrink {
  flex-shrink: 1;
}

.shrink-0 {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

.flex-grow-0 {
  flex-grow: 0;
}

.grow {
  flex-grow: 1;
}

.table-fixed {
  table-layout: fixed;
}

.border-collapse {
  border-collapse: collapse;
}

.border-separate {
  border-collapse: separate;
}

.-translate-x-1\/2, .-translate-x-\[50\%\] {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-\[175\%\] {
  --tw-translate-x: 175%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-\[-50\%\] {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-90 {
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-90 {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-\[-90deg\] {
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.\!scale-\[100\%\] {
  --tw-scale-x: 100% !important;
  --tw-scale-y: 100% !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.scale-\[70\%\] {
  --tw-scale-x: 70%;
  --tw-scale-y: 70%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes slideAbsolute {
  0% {
    left: -20%;
  }

  50% {
    left: 80%;
  }

  100% {
    left: -20%;
  }
}

.animate-slideAbsolute {
  animation: slideAbsolute 2s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: spin 1s linear infinite;
}

.cursor-default {
  cursor: default;
}

.cursor-move {
  cursor: move;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.select-none {
  -ms-user-select: none;
  user-select: none;
}

.resize {
  resize: both;
}

.list-inside {
  list-style-position: inside;
}

.list-outside {
  list-style-position: outside;
}

.list-decimal {
  list-style-type: decimal;
}

.list-disc {
  list-style-type: disc;
}

.appearance-none {
  -ms-appearance: none;
  appearance: none;
}

.auto-cols-max {
  grid-auto-columns: max-content;
}

.auto-rows-auto {
  grid-auto-rows: auto;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.grid-cols-\[110px_1fr\] {
  grid-template-columns: 110px 1fr;
}

.grid-rows-6 {
  grid-template-rows: repeat(6, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.content-center {
  align-content: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-evenly {
  justify-content: space-evenly;
}

.gap-1 {
  gap: .25rem;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-12 {
  gap: 3rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-\[4px\] {
  gap: 4px;
}

.gap-\[7px\] {
  gap: 7px;
}

.gap-x-4 {
  column-gap: 1rem;
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.75rem * var(--tw-space-x-reverse));
  margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)) );
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)) );
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)) );
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-light-control > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(199, 199, 199, var(--tw-divide-opacity));
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.self-stretch {
  align-self: stretch;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-y-visible {
  overflow-y: visible;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-clip {
  text-overflow: clip;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded {
  border-radius: .25rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-sm {
  border-radius: .125rem;
}

.rounded-b {
  border-bottom-left-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.rounded-b-none {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.rounded-l {
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.rounded-r {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.rounded-r-sm {
  border-top-right-radius: .125rem;
  border-bottom-right-radius: .125rem;
}

.rounded-t {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.rounded-t-none {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.rounded-bl {
  border-bottom-left-radius: .25rem;
}

.rounded-br {
  border-bottom-right-radius: .25rem;
}

.rounded-br-none {
  border-bottom-right-radius: 0;
}

.rounded-tl {
  border-top-left-radius: .25rem;
}

.rounded-tl-none {
  border-top-left-radius: 0;
}

.rounded-tr {
  border-top-right-radius: .25rem;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.border-2 {
  border-width: 2px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-0 {
  border-bottom-width: 0;
}

.border-l {
  border-left-width: 1px;
}

.border-l-0 {
  border-left-width: 0;
}

.border-r {
  border-right-width: 1px;
}

.border-r-0 {
  border-right-width: 0;
}

.border-t {
  border-top-width: 1px;
}

.border-t-0 {
  border-top-width: 0;
}

.border-solid {
  border-style: solid;
}

.border-dashed {
  border-style: dashed;
}

.border-dotted {
  border-style: dotted;
}

.border-none {
  border-style: none;
}

.\!border-blue-600 {
  --tw-border-opacity: 1 !important;
  border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
}

.\!border-light-alert-critical {
  --tw-border-opacity: 1 !important;
  border-color: rgba(205, 43, 49, var(--tw-border-opacity)) !important;
}

.\!border-light-alert-success {
  --tw-border-opacity: 1 !important;
  border-color: rgba(61, 154, 80, var(--tw-border-opacity)) !important;
}

.border-black {
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
}

.border-dark-base {
  --tw-border-opacity: 1;
  border-color: rgba(52, 52, 52, var(--tw-border-opacity));
}

.border-light-alert {
  border-color: rgba(0, 0, 0, .047);
}

.border-light-alert-success {
  --tw-border-opacity: 1;
  border-color: rgba(61, 154, 80, var(--tw-border-opacity));
}

.border-light-base {
  --tw-border-opacity: 1;
  border-color: rgba(226, 226, 226, var(--tw-border-opacity));
}

.border-light-brand {
  --tw-border-opacity: 1;
  border-color: rgba(73, 72, 238, var(--tw-border-opacity));
}

.border-light-control {
  --tw-border-opacity: 1;
  border-color: rgba(199, 199, 199, var(--tw-border-opacity));
}

.border-red-200 {
  --tw-border-opacity: 1;
  border-color: rgba(254, 202, 202, var(--tw-border-opacity));
}

.border-red-light-11 {
  --tw-border-opacity: 1;
  border-color: rgba(205, 43, 49, var(--tw-border-opacity));
}

.border-transparent {
  border-color: rgba(0, 0, 0, 0);
}

.border-b-\[\#FCFCFC\] {
  --tw-border-opacity: 1;
  border-bottom-color: rgba(252, 252, 252, var(--tw-border-opacity));
}

.border-b-light-control {
  --tw-border-opacity: 1;
  border-bottom-color: rgba(199, 199, 199, var(--tw-border-opacity));
}

.border-b-transparent {
  border-bottom-color: rgba(0, 0, 0, 0);
}

.\!bg-light-base {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(252, 252, 252, var(--tw-bg-opacity)) !important;
}

.\!bg-light-selected {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(234, 234, 254, var(--tw-bg-opacity)) !important;
}

.bg-\[\#999999\] {
  --tw-bg-opacity: 1;
  background-color: rgba(153, 153, 153, var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}

.bg-dark-alert-critical {
  --tw-bg-opacity: 1;
  background-color: rgba(84, 27, 31, var(--tw-bg-opacity));
}

.bg-dark-base {
  --tw-bg-opacity: 1;
  background-color: rgba(28, 28, 28, var(--tw-bg-opacity));
}

.bg-dark-hover {
  --tw-bg-opacity: 1;
  background-color: rgba(40, 40, 40, var(--tw-bg-opacity));
}

.bg-dark-selected {
  --tw-bg-opacity: 1;
  background-color: rgba(34, 52, 110, var(--tw-bg-opacity));
}

.bg-dark-selected-faint {
  --tw-bg-opacity: 1;
  background-color: rgba(21, 25, 45, var(--tw-bg-opacity));
}

.bg-dark-shade {
  --tw-bg-opacity: 1;
  background-color: rgba(35, 35, 35, var(--tw-bg-opacity));
}

.bg-green-light-9 {
  --tw-bg-opacity: 1;
  background-color: rgba(70, 167, 88, var(--tw-bg-opacity));
}

.bg-grey-light-3 {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 243, 243, var(--tw-bg-opacity));
}

.bg-grey-light-5 {
  --tw-bg-opacity: 1;
  background-color: rgba(232, 232, 232, var(--tw-bg-opacity));
}

.bg-grey-light-9 {
  --tw-bg-opacity: 1;
  background-color: rgba(143, 143, 143, var(--tw-bg-opacity));
}

.bg-inherit {
  background-color: inherit;
}

.bg-light-alert-critical {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 229, 229, var(--tw-bg-opacity));
}

.bg-light-alert-success {
  --tw-bg-opacity: 1;
  background-color: rgba(223, 243, 223, var(--tw-bg-opacity));
}

.bg-light-alert-warning {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 248, 187, var(--tw-bg-opacity));
}

.bg-light-base {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 252, 252, var(--tw-bg-opacity));
}

.bg-light-brand {
  --tw-bg-opacity: 1;
  background-color: rgba(73, 72, 238, var(--tw-bg-opacity));
}

.bg-light-control-active {
  --tw-bg-opacity: 1;
  background-color: rgba(191, 191, 249, var(--tw-bg-opacity));
}

.bg-light-control-primary {
  --tw-bg-opacity: 1;
  background-color: rgba(73, 72, 238, var(--tw-bg-opacity));
}

.bg-light-control-secondary {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 248, 248, var(--tw-bg-opacity));
}

.bg-light-header-bg {
  --tw-bg-opacity: 1;
  background-color: rgba(37, 36, 65, var(--tw-bg-opacity));
}

.bg-light-hover {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 243, 243, var(--tw-bg-opacity));
}

.bg-light-selected {
  --tw-bg-opacity: 1;
  background-color: rgba(234, 234, 254, var(--tw-bg-opacity));
}

.bg-light-selected-faint {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 248, 252, var(--tw-bg-opacity));
}

.bg-light-shade, .bg-light-site-bg {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 243, 243, var(--tw-bg-opacity));
}

.bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: rgba(0, 0, 0, 0);
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.bg-white\/50 {
  background-color: rgba(255, 255, 255, .5);
}

.\!bg-opacity-10 {
  --tw-bg-opacity: .1 !important;
}

.bg-opacity-0 {
  --tw-bg-opacity: 0;
}

.bg-opacity-25 {
  --tw-bg-opacity: .25;
}

.fill-black {
  fill: #000;
}

.fill-light-alert-success {
  fill: #3d9a50;
}

.fill-light-brand {
  fill: #4948ee;
}

.fill-light-muted {
  fill: #6f6f6f;
}

.fill-neutral-700 {
  fill: #404040;
}

.fill-white {
  fill: #fff;
}

.stroke-grey-light-7 {
  stroke: #dbdbdb;
}

.stroke-light-alert-success {
  stroke: #3d9a50;
}

.stroke-light-faint {
  stroke: #c7c7c7;
}

.stroke-light-muted {
  stroke: #6f6f6f;
}

.stroke-neutral-700 {
  stroke: #404040;
}

.\!p-0 {
  padding: 0 !important;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: .25rem;
}

.p-10 {
  padding: 2.5rem;
}

.p-16 {
  padding: 4rem;
}

.p-2 {
  padding: .5rem;
}

.p-3 {
  padding: .75rem;
}

.p-3\.5 {
  padding: .875rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-8 {
  padding: 2rem;
}

.p-\[25px\] {
  padding: 25px;
}

.p-px {
  padding: 1px;
}

.\!px-12 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.\!px-3 {
  padding-left: .75rem !important;
  padding-right: .75rem !important;
}

.\!px-\[9px\] {
  padding-left: 9px !important;
  padding-right: 9px !important;
}

.\!py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.\!py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

.\!py-12 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.\!py-\[3px\] {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-20 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-\[3\.5px\] {
  padding-top: 3.5px;
  padding-bottom: 3.5px;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pb-32 {
  padding-bottom: 8rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pb-\[8px\] {
  padding-bottom: 8px;
}

.pl-1 {
  padding-left: .25rem;
}

.pl-1\.5 {
  padding-left: .375rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pl-\[4px\] {
  padding-left: 4px;
}

.pr-0 {
  padding-right: 0;
}

.pr-1 {
  padding-right: .25rem;
}

.pr-10 {
  padding-right: 2.5rem;
}

.pr-28 {
  padding-right: 7rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-6 {
  padding-right: 1.5rem;
}

.pr-\[16px\] {
  padding-right: 16px;
}

.pr-\[7px\] {
  padding-right: 7px;
}

.pt-0 {
  padding-top: 0;
}

.pt-0\.5 {
  padding-top: .125rem;
}

.pt-1 {
  padding-top: .25rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-3 {
  padding-top: .75rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pt-\[6px\] {
  padding-top: 6px;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.align-middle {
  vertical-align: middle;
}

.align-bottom {
  vertical-align: bottom;
}

.font-mono {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

.\!text-base {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-\[12px\] {
  font-size: 12px;
}

.text-\[16px\] {
  font-size: 16px;
}

.text-\[18px\] {
  font-size: 18px;
}

.text-\[20px\] {
  font-size: 20px;
}

.text-\[32px\] {
  font-size: 32px;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.leading-3 {
  line-height: .75rem;
}

.leading-4 {
  line-height: 1rem;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-7 {
  line-height: 1.75rem;
}

.leading-8 {
  line-height: 2rem;
}

.leading-9 {
  line-height: 2.25rem;
}

.leading-loose {
  line-height: 2;
}

.leading-none {
  line-height: 1;
}

.leading-relaxed {
  line-height: 1.625;
}

.leading-tight {
  line-height: 1.25;
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-text-opacity));
}

.text-light-alert-critical {
  --tw-text-opacity: 1;
  color: rgba(205, 43, 49, var(--tw-text-opacity));
}

.text-light-alert-success {
  --tw-text-opacity: 1;
  color: rgba(61, 154, 80, var(--tw-text-opacity));
}

.text-light-base {
  --tw-text-opacity: 1;
  color: rgba(23, 23, 23, var(--tw-text-opacity));
}

.text-light-brand {
  --tw-text-opacity: 1;
  color: rgba(73, 72, 238, var(--tw-text-opacity));
}

.text-light-faint {
  --tw-text-opacity: 1;
  color: rgba(199, 199, 199, var(--tw-text-opacity));
}

.text-light-link {
  --tw-text-opacity: 1;
  color: rgba(73, 72, 238, var(--tw-text-opacity));
}

.text-light-muted {
  --tw-text-opacity: 1;
  color: rgba(111, 111, 111, var(--tw-text-opacity));
}

.text-orange-500 {
  --tw-text-opacity: 1;
  color: rgba(249, 115, 22, var(--tw-text-opacity));
}

.text-red-900 {
  --tw-text-opacity: 1;
  color: rgba(127, 29, 29, var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.text-opacity-50 {
  --tw-text-opacity: .5;
}

.underline {
  text-decoration-line: underline;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.\!opacity-25 {
  opacity: .25 !important;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-25 {
  opacity: .25;
}

.opacity-50 {
  opacity: .5;
}

.opacity-60 {
  opacity: .6;
}

.opacity-75 {
  opacity: .75;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px -1px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.shadow-focus {
  --tw-shadow: inset 0 0 0 3px #c3dafe;
  --tw-shadow-colored: inset 0 0 0 3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.shadow-none {
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid rgba(0, 0, 0, 0);
}

.outline {
  outline-style: solid;
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0, 0, 0, 0));
}

.ring-light-brand {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(73, 72, 238, var(--tw-ring-opacity));
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow {
  --tw-drop-shadow: drop-shadow(0 1px 2px rgba(0, 0, 0, .1)) drop-shadow(0 1px 1px rgba(0, 0, 0, .06));
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.\!filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-none {
  transition-property: none;
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.\[box-shadow\:-2px_0_8px_rgba\(0\,0\,0\,0\.15\)\] {
  box-shadow: -2px 0 8px rgba(0, 0, 0, .15);
}

.\[box-shadow\:2px_0_8px_rgba\(0\,0\,0\,0\.15\)\] {
  box-shadow: 2px 0 8px rgba(0, 0, 0, .15);
}

html {
  width: 100%;
  height: 100%;
  font-family: Messina, sans-serif;
  font-size: 14px;
  line-height: 1.5;
}

body {
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

b {
  font-weight: 600;
}

a, a:active, a:focus {
  outline-offset: 2px;
  border-style: none;
  outline: 2px solid rgba(0, 0, 0, 0);
}

button:focus {
  outline-offset: 2px;
  outline: 2px solid rgba(0, 0, 0, 0);
}

.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.placeholder\:text-light-muted::-ms-input-placeholder {
  --tw-text-opacity: 1;
  color: rgba(111, 111, 111, var(--tw-text-opacity));
}

.placeholder\:text-light-muted::placeholder {
  --tw-text-opacity: 1;
  color: rgba(111, 111, 111, var(--tw-text-opacity));
}

.first\:rounded-t:first-child {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.last\:rounded-b:last-child {
  border-bottom-left-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.last\:border-0:last-child {
  border-width: 0;
}

.last\:border-b:last-child {
  border-bottom-width: 1px;
}

.last\:border-b-0:last-child {
  border-bottom-width: 0;
}

.last\:border-none:last-child {
  border-style: none;
}

.hover\:border-light-control-hover:hover {
  --tw-border-opacity: 1;
  border-color: rgba(143, 143, 143, var(--tw-border-opacity));
}

.hover\:bg-black:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}

.hover\:bg-light-base:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 252, 252, var(--tw-bg-opacity));
}

.hover\:bg-light-control-primary-hover:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(82, 81, 239, var(--tw-bg-opacity));
}

.hover\:bg-light-control-secondary-hover:hover, .hover\:bg-light-hover:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 243, 243, var(--tw-bg-opacity));
}

.hover\:bg-light-hover-faint:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 248, 248, var(--tw-bg-opacity));
}

.hover\:bg-light-selected:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(234, 234, 254, var(--tw-bg-opacity));
}

.hover\:bg-light-selected-faint:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 248, 252, var(--tw-bg-opacity));
}

.hover\:bg-light-shade:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 243, 243, var(--tw-bg-opacity));
}

.hover\:bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.hover\:\!bg-opacity-10:hover {
  --tw-bg-opacity: .1 !important;
}

.hover\:text-light-faint:hover {
  --tw-text-opacity: 1;
  color: rgba(199, 199, 199, var(--tw-text-opacity));
}

.hover\:text-light-link-hover:hover {
  --tw-text-opacity: 1;
  color: rgba(93, 92, 240, var(--tw-text-opacity));
}

.hover\:text-opacity-75:hover {
  --tw-text-opacity: .75;
}

.hover\:opacity-75:hover {
  opacity: .75;
}

.hover\:opacity-80:hover {
  opacity: .8;
}

.focus\:border-light-control-focus:focus {
  --tw-border-opacity: 1;
  border-color: rgba(73, 72, 238, var(--tw-border-opacity));
}

.focus\:border-light-control-hover:focus {
  --tw-border-opacity: 1;
  border-color: rgba(143, 143, 143, var(--tw-border-opacity));
}

.focus\:bg-light-control-primary-hover:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(82, 81, 239, var(--tw-bg-opacity));
}

.focus\:bg-light-control-secondary-hover:focus, .focus\:bg-light-shade:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 243, 243, var(--tw-bg-opacity));
}

.focus\:text-dark-muted:focus {
  --tw-text-opacity: 1;
  color: rgba(160, 160, 160, var(--tw-text-opacity));
}

.focus\:text-light-link-hover:focus {
  --tw-text-opacity: 1;
  color: rgba(93, 92, 240, var(--tw-text-opacity));
}

.focus\:opacity-75:focus {
  opacity: .75;
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid rgba(0, 0, 0, 0);
}

.active\:cursor-move:active {
  cursor: move;
}

.active\:border-light-control:active {
  --tw-border-opacity: 1;
  border-color: rgba(199, 199, 199, var(--tw-border-opacity));
}

.active\:bg-light-control-primary-hover:active {
  --tw-bg-opacity: 1;
  background-color: rgba(82, 81, 239, var(--tw-bg-opacity));
}

.active\:outline-none:active {
  outline-offset: 2px;
  outline: 2px solid rgba(0, 0, 0, 0);
}

.disabled\:pointer-events-none:disabled {
  pointer-events: none;
}

.disabled\:opacity-50:disabled {
  opacity: .5;
}

.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}

:is(.dark .dark\:divide-dark-control) > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(80, 80, 80, var(--tw-divide-opacity));
}

:is(.dark .dark\:border) {
  border-width: 1px;
}

:is(.dark .dark\:border-dark-alert) {
  border-color: rgba(255, 255, 255, .07);
}

:is(.dark .dark\:border-dark-alert-success) {
  --tw-border-opacity: 1;
  border-color: rgba(70, 167, 88, var(--tw-border-opacity));
}

:is(.dark .dark\:border-dark-base) {
  --tw-border-opacity: 1;
  border-color: rgba(52, 52, 52, var(--tw-border-opacity));
}

:is(.dark .dark\:border-dark-brand-alt) {
  --tw-border-opacity: 1;
  border-color: rgba(132, 157, 255, var(--tw-border-opacity));
}

:is(.dark .dark\:border-dark-control) {
  --tw-border-opacity: 1;
  border-color: rgba(80, 80, 80, var(--tw-border-opacity));
}

:is(.dark .dark\:border-red-dark-11) {
  --tw-border-opacity: 1;
  border-color: rgba(255, 99, 105, var(--tw-border-opacity));
}

:is(.dark .dark\:border-white) {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}

:is(.dark .dark\:border-b-\[\#1C1C1C\]) {
  --tw-border-opacity: 1;
  border-bottom-color: rgba(28, 28, 28, var(--tw-border-opacity));
}

:is(.dark .dark\:border-b-dark-base) {
  --tw-border-opacity: 1;
  border-bottom-color: rgba(52, 52, 52, var(--tw-border-opacity));
}

:is(.dark .dark\:border-b-dark-control) {
  --tw-border-opacity: 1;
  border-bottom-color: rgba(80, 80, 80, var(--tw-border-opacity));
}

:is(.dark .dark\:\!bg-dark-base) {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(28, 28, 28, var(--tw-bg-opacity)) !important;
}

:is(.dark .dark\:\!bg-dark-selected) {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(34, 52, 110, var(--tw-bg-opacity)) !important;
}

:is(.dark .dark\:bg-dark-alert-critical) {
  --tw-bg-opacity: 1;
  background-color: rgba(84, 27, 31, var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-dark-alert-success) {
  --tw-bg-opacity: 1;
  background-color: rgba(29, 68, 39, var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-dark-alert-warning) {
  --tw-bg-opacity: 1;
  background-color: rgba(117, 94, 28, var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-dark-base) {
  --tw-bg-opacity: 1;
  background-color: rgba(28, 28, 28, var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-dark-brand) {
  --tw-bg-opacity: 1;
  background-color: rgba(237, 237, 237, var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-dark-control-primary) {
  --tw-bg-opacity: 1;
  background-color: rgba(62, 99, 221, var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-dark-control-secondary) {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 52, 52, var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-dark-header-bg) {
  --tw-bg-opacity: 1;
  background-color: rgba(28, 28, 28, var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-dark-hover) {
  --tw-bg-opacity: 1;
  background-color: rgba(40, 40, 40, var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-dark-selected) {
  --tw-bg-opacity: 1;
  background-color: rgba(34, 52, 110, var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-dark-selected-faint) {
  --tw-bg-opacity: 1;
  background-color: rgba(21, 25, 45, var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-dark-shade) {
  --tw-bg-opacity: 1;
  background-color: rgba(35, 35, 35, var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-dark-site-bg) {
  --tw-bg-opacity: 1;
  background-color: rgba(22, 22, 22, var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-dark-sub\/50) {
  background-color: rgba(22, 22, 22, .5);
}

:is(.dark .dark\:bg-green-dark-9) {
  --tw-bg-opacity: 1;
  background-color: rgba(70, 167, 88, var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-grey-dark-3) {
  --tw-bg-opacity: 1;
  background-color: rgba(35, 35, 35, var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-grey-dark-5) {
  --tw-bg-opacity: 1;
  background-color: rgba(46, 46, 46, var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-grey-dark-9) {
  --tw-bg-opacity: 1;
  background-color: rgba(112, 112, 112, var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-transparent) {
  background-color: rgba(0, 0, 0, 0);
}

:is(.dark .dark\:bg-white) {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

:is(.dark .dark\:fill-dark-brand-alt) {
  fill: #849dff;
}

:is(.dark .dark\:fill-dark-muted) {
  fill: #a0a0a0;
}

:is(.dark .dark\:fill-grey-dark-2) {
  fill: #1c1c1c;
}

:is(.dark .dark\:fill-neutral-200) {
  fill: #e5e5e5;
}

:is(.dark .dark\:fill-white) {
  fill: #fff;
}

:is(.dark .dark\:stroke-dark-faint) {
  stroke: #505050;
}

:is(.dark .dark\:stroke-dark-muted) {
  stroke: #a0a0a0;
}

:is(.dark .dark\:stroke-grey-dark-7) {
  stroke: #3e3e3e;
}

:is(.dark .dark\:stroke-neutral-200) {
  stroke: #e5e5e5;
}

:is(.dark .dark\:text-dark-alert-critical) {
  --tw-text-opacity: 1;
  color: rgba(242, 85, 90, var(--tw-text-opacity));
}

:is(.dark .dark\:text-dark-alert-success) {
  --tw-text-opacity: 1;
  color: rgba(70, 167, 88, var(--tw-text-opacity));
}

:is(.dark .dark\:text-dark-base) {
  --tw-text-opacity: 1;
  color: rgba(237, 237, 237, var(--tw-text-opacity));
}

:is(.dark .dark\:text-dark-brand-alt) {
  --tw-text-opacity: 1;
  color: rgba(132, 157, 255, var(--tw-text-opacity));
}

:is(.dark .dark\:text-dark-faint) {
  --tw-text-opacity: 1;
  color: rgba(80, 80, 80, var(--tw-text-opacity));
}

:is(.dark .dark\:text-dark-link) {
  --tw-text-opacity: 1;
  color: rgba(132, 157, 255, var(--tw-text-opacity));
}

:is(.dark .dark\:text-dark-muted) {
  --tw-text-opacity: 1;
  color: rgba(160, 160, 160, var(--tw-text-opacity));
}

:is(.dark .dark\:text-white) {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

:is(.dark .dark\:\[box-shadow\:-2px_0_8px_rgba\(255\,255\,255\,0\.05\)\]) {
  box-shadow: -2px 0 8px rgba(255, 255, 255, .05);
}

:is(.dark .dark\:\[box-shadow\:2px_0_8px_rgba\(255\,255\,255\,0\.05\)\]) {
  box-shadow: 2px 0 8px rgba(255, 255, 255, .05);
}

:is(.dark .dark\:placeholder\:text-dark-muted)::-ms-input-placeholder {
  --tw-text-opacity: 1;
  color: rgba(160, 160, 160, var(--tw-text-opacity));
}

:is(.dark .dark\:placeholder\:text-dark-muted)::placeholder {
  --tw-text-opacity: 1;
  color: rgba(160, 160, 160, var(--tw-text-opacity));
}

:is(.dark .dark\:hover\:border-dark-control-hover:hover) {
  --tw-border-opacity: 1;
  border-color: rgba(112, 112, 112, var(--tw-border-opacity));
}

:is(.dark .dark\:hover\:bg-dark-base:hover) {
  --tw-bg-opacity: 1;
  background-color: rgba(28, 28, 28, var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:bg-dark-control-primary-hover:hover) {
  --tw-bg-opacity: 1;
  background-color: rgba(47, 78, 178, var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:bg-dark-hover:hover) {
  --tw-bg-opacity: 1;
  background-color: rgba(40, 40, 40, var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:bg-dark-hover-faint:hover) {
  --tw-bg-opacity: 1;
  background-color: rgba(35, 35, 35, var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:bg-dark-selected:hover) {
  --tw-bg-opacity: 1;
  background-color: rgba(34, 52, 110, var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:bg-dark-selected-faint:hover) {
  --tw-bg-opacity: 1;
  background-color: rgba(21, 25, 45, var(--tw-bg-opacity));
}

:is(.dark .hover\:dark\:bg-dark-hover):hover {
  --tw-bg-opacity: 1;
  background-color: rgba(40, 40, 40, var(--tw-bg-opacity));
}

:is(.dark .hover\:dark\:bg-dark-selected):hover {
  --tw-bg-opacity: 1;
  background-color: rgba(34, 52, 110, var(--tw-bg-opacity));
}

:is(.dark .hover\:dark\:bg-dark-shade):hover {
  --tw-bg-opacity: 1;
  background-color: rgba(35, 35, 35, var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:text-dark-link-hover:hover) {
  --tw-text-opacity: 1;
  color: rgba(83, 115, 231, var(--tw-text-opacity));
}

:is(.dark .dark\:focus\:border-dark-control-focus:focus) {
  --tw-border-opacity: 1;
  border-color: rgba(132, 157, 255, var(--tw-border-opacity));
}

:is(.dark .dark\:focus\:border-dark-control-hover:focus) {
  --tw-border-opacity: 1;
  border-color: rgba(112, 112, 112, var(--tw-border-opacity));
}

:is(.dark .dark\:focus\:bg-dark-selected:focus) {
  --tw-bg-opacity: 1;
  background-color: rgba(34, 52, 110, var(--tw-bg-opacity));
}

:is(.dark .dark\:focus\:bg-dark-shade:focus) {
  --tw-bg-opacity: 1;
  background-color: rgba(35, 35, 35, var(--tw-bg-opacity));
}

:is(.dark .focus\:dark\:bg-dark-shade):focus {
  --tw-bg-opacity: 1;
  background-color: rgba(35, 35, 35, var(--tw-bg-opacity));
}

:is(.dark .dark\:focus\:text-dark-link-hover:focus) {
  --tw-text-opacity: 1;
  color: rgba(83, 115, 231, var(--tw-text-opacity));
}

:is(.dark .active\:dark\:border-dark-control):active {
  --tw-border-opacity: 1;
  border-color: rgba(80, 80, 80, var(--tw-border-opacity));
}

:is(.dark .dark\:active\:bg-dark-selected:active) {
  --tw-bg-opacity: 1;
  background-color: rgba(34, 52, 110, var(--tw-bg-opacity));
}

@media (min-width: 640px) {
  .sm\:right-\[80px\] {
    right: 80px;
  }

  .sm\:-my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }

  .sm\:-mb-3 {
    margin-bottom: -.75rem;
  }

  .sm\:-mb-4 {
    margin-bottom: -1rem;
  }

  .sm\:mb-8 {
    margin-bottom: 2rem;
  }

  .sm\:mr-4 {
    margin-right: 1rem;
  }

  .sm\:block {
    display: block;
  }

  .sm\:inline {
    display: inline;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:grid {
    display: grid;
  }

  .sm\:contents {
    display: contents;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:w-1\/3 {
    width: 33.3333%;
  }

  .sm\:w-2\/3 {
    width: 66.6667%;
  }

  .sm\:w-5\/6 {
    width: 83.3333%;
  }

  .sm\:w-96 {
    width: 24rem;
  }

  .sm\:w-\[500px\] {
    width: 500px;
  }

  .sm\:w-fit {
    width: fit-content;
  }

  .sm\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .sm\:overflow-auto {
    overflow: auto;
  }

  .sm\:overflow-hidden {
    overflow: hidden;
  }

  .sm\:overflow-y-hidden {
    overflow-y: hidden;
  }

  .sm\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .sm\:pb-4 {
    padding-bottom: 1rem;
  }
}

@media (min-width: 768px) {
  .md\:order-2 {
    order: 2;
  }

  .md\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .md\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .md\:mb-0 {
    margin-bottom: 0;
  }

  .md\:mb-12 {
    margin-bottom: 3rem;
  }

  .md\:mb-4 {
    margin-bottom: 1rem;
  }

  .md\:ml-2 {
    margin-left: .5rem;
  }

  .md\:mr-8 {
    margin-right: 2rem;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:mt-2 {
    margin-top: .5rem;
  }

  .md\:block {
    display: block;
  }

  .md\:inline {
    display: inline;
  }

  .md\:flex {
    display: flex;
  }

  .md\:grid {
    display: grid;
  }

  .md\:contents {
    display: contents;
  }

  .md\:hidden {
    display: none;
  }

  .md\:min-h-\[324px\] {
    min-height: 324px;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:w-1\/3 {
    width: 33.3333%;
  }

  .md\:w-1\/4 {
    width: 25%;
  }

  .md\:w-2\/3 {
    width: 66.6667%;
  }

  .md\:w-4\/5 {
    width: 80%;
  }

  .md\:w-\[220px\] {
    width: 220px;
  }

  .md\:w-auto {
    width: auto;
  }

  .md\:flex-1 {
    flex: 1;
  }

  .md\:flex-shrink-0 {
    flex-shrink: 0;
  }

  .md\:flex-grow {
    flex-grow: 1;
  }

  .md\:flex-grow-0 {
    flex-grow: 0;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:flex-col {
    flex-direction: column;
  }

  .md\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .md\:overflow-hidden {
    overflow: hidden;
  }

  .md\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .md\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .md\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .md\:pb-4 {
    padding-bottom: 1rem;
  }

  .md\:pb-5 {
    padding-bottom: 1.25rem;
  }

  .md\:pb-6 {
    padding-bottom: 1.5rem;
  }

  .md\:pr-32 {
    padding-right: 8rem;
  }

  .md\:pt-8 {
    padding-top: 2rem;
  }

  .md\:text-right {
    text-align: right;
  }

  .md\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .md\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .md\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .md\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .md\:font-light {
    font-weight: 300;
  }
}

@media (min-width: 1024px) {
  .lg\:visible {
    visibility: visible;
  }

  .lg\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .lg\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .lg\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .lg\:mb-0 {
    margin-bottom: 0;
  }

  .lg\:ml-1 {
    margin-left: .25rem;
  }

  .lg\:ml-4 {
    margin-left: 1rem;
  }

  .lg\:mr-0 {
    margin-right: 0;
  }

  .lg\:mr-2 {
    margin-right: .5rem;
  }

  .lg\:mr-3 {
    margin-right: .75rem;
  }

  .lg\:mr-4 {
    margin-right: 1rem;
  }

  .lg\:mt-4 {
    margin-top: 1rem;
  }

  .lg\:block {
    display: block;
  }

  .lg\:inline {
    display: inline;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:grid {
    display: grid;
  }

  .lg\:\!hidden {
    display: none !important;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:w-1\/2 {
    width: 50%;
  }

  .lg\:w-1\/3 {
    width: 33.3333%;
  }

  .lg\:w-2\/3 {
    width: 66.6667%;
  }

  .lg\:w-2\/5 {
    width: 40%;
  }

  .lg\:w-240 {
    width: 58rem;
  }

  .lg\:w-3\/5 {
    width: 60%;
  }

  .lg\:w-60 {
    width: 15rem;
  }

  .lg\:w-\[320px\] {
    width: 320px;
  }

  .lg\:min-w-0 {
    min-width: 0;
  }

  .lg\:min-w-\[800px\] {
    min-width: 800px;
  }

  .lg\:max-w-\[280px\] {
    max-width: 280px;
  }

  .lg\:max-w-\[50\%\] {
    max-width: 50%;
  }

  .lg\:grid-cols-\[80px_1fr\] {
    grid-template-columns: 80px 1fr;
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .lg\:content-center {
    align-content: center;
  }

  .lg\:content-evenly {
    align-content: space-evenly;
  }

  .lg\:p-12 {
    padding: 3rem;
  }

  .lg\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .lg\:py-\[7px\] {
    padding-top: 7px;
    padding-bottom: 7px;
  }

  .lg\:pb-4 {
    padding-bottom: 1rem;
  }

  .lg\:pl-10 {
    padding-left: 2.5rem;
  }

  .lg\:pl-\[11px\] {
    padding-left: 11px;
  }

  .lg\:pr-20 {
    padding-right: 5rem;
  }

  .lg\:pr-\[14px\] {
    padding-right: 14px;
  }

  .lg\:pt-12 {
    padding-top: 3rem;
  }

  .lg\:text-right {
    text-align: right;
  }

  .lg\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .lg\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

@media (min-width: 1280px) {
  .xl\:-mt-6 {
    margin-top: -1.5rem;
  }

  .xl\:ml-0 {
    margin-left: 0;
  }

  .xl\:mt-0 {
    margin-top: 0;
  }

  .xl\:block {
    display: block;
  }

  .xl\:flex {
    display: flex;
  }

  .xl\:w-1\/2 {
    width: 50%;
  }

  .xl\:w-1\/4 {
    width: 25%;
  }

  .xl\:w-2\/3 {
    width: 66.6667%;
  }

  .xl\:flex-row {
    flex-direction: row;
  }

  .xl\:flex-col {
    flex-direction: column;
  }

  .xl\:justify-between {
    justify-content: space-between;
  }

  .xl\:overflow-x-visible {
    overflow-x: visible;
  }

  .xl\:pb-0 {
    padding-bottom: 0;
  }

  .xl\:pb-6 {
    padding-bottom: 1.5rem;
  }

  .xl\:text-left {
    text-align: left;
  }

  .xl\:text-right {
    text-align: right;
  }

  .xl\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

@media (min-width: 1536px) {
  .\32 xl\:w-1\/12 {
    width: 8.33333%;
  }

  .\32 xl\:w-11\/12 {
    width: 91.6667%;
  }
}

@media (min-width: 480px) {
  .xs\:ml-auto {
    margin-left: auto;
  }

  .xs\:block {
    display: block;
  }

  .xs\:hidden {
    display: none;
  }

  .xs\:flex-row {
    flex-direction: row;
  }

  .xs\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .xs\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (max-height: 850px) {
  .short\:relative {
    position: relative;
  }
}

@media (min-width: 1024px) {
  .\[\&\>\*\>\*\]\:lg\:inline > * > * {
    display: inline;
  }
}

.\[\&\>span\]\:opacity-0 > span {
  opacity: 0;
}

/*# sourceMappingURL=index.2cafd52e.css.map */

.rdtPicker {
  @apply bg-light-base dark:bg-dark-base rounded border-light-base dark:border-dark-base;
}

.rdtPicker th {
  @apply border-light-base dark:border-dark-base;
}

.rdtPicker thead tr:first-of-type th {
  @apply text-light-base dark:text-dark-base font-bold;
}

.rdtPicker thead tr:first-of-type th:hover {
  @apply bg-light-base dark:bg-dark-base;
}

.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  @apply bg-light-hover dark:bg-dark-hover rounded;
}

td.rdtMonth:hover,
td.rdtYear:hover {
  @apply bg-light-hover dark:bg-dark-hover rounded;
}

.rdtPicker td.rdtActive.rdtToday:before,
.rdtPicker td.rdtToday:before {
  @apply border-b-light-brand dark:border-b-dark-brand-alt;
}

.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  text-shadow: none;
  @apply bg-light-selected dark:bg-dark-selected text-light-base dark:text-dark-base rounded;
}

.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
  @apply text-light-faint dark:text-dark-faint;
}

.rdtPicker tfoot {
  @apply border-t border-light-base dark:border-dark-base;
}

.rdtCounter .rdtBtn:hover {
  @apply bg-light-hover dark:bg-dark-hover;
}
